:root {
  --main-bg-colour: #0e141b
}


html {
  font-family: helvetica;
  color: white;
}

body {
  margin: 0;
  background-color: var(--main-bg-colour)
}

p {
  text-align: center;
}

.nav {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  width: 80vw;
  margin: auto;
  color: white;
}

.nav-div ul {
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 0;
}

ul {
  margin: 0
}

li {
  list-style: none;
}

a {
  color: white;
  text-decoration: none;
}

.password-div h1 {
  height: 2em;
  word-wrap: break-word;
}

.footer {
  color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.form-div {
  display: flex;
  width: 500px;
  margin: 0 auto;
  align-items: center;
  font-size: 20px;
  justify-content: space-evenly;
  padding: 0px 10px;
}

.password-div {
  text-align: center;
  max-width: 700px;
  width: 80vw;
  background-color: rgb(36, 33, 33);
  height: auto;
  border-radius: 10px;
  margin: 15px auto;
  border: 2px solid white;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  }

.password-form {
  display: flex;
  margin: 0 auto;
  flex-direction: column;
}

.labels-div {
  display: flex;
  flex-direction: column;
  align-items: center;    
  height: 200px;
  justify-content: space-around
}

.input-div input {
  height: 1.5em;
  width: 200px;
}

.label-div, .input-div {
  padding-bottom: 15px;
}

.password-div h1 {
  font-size: 30px;
}

.button-div {
  text-align: center;
  padding-top: 15px;
}

.red-text {
  color: red
}

.orange-text {
  color: orange
}

.green-text {
  color: green
}

.container {
  max-width: 700px;
  width: 80vw;
  background-color: rgb(36, 33, 33);
  height: auto;
  border-radius: 10px;
  margin: 15px auto;
  border: 2px solid white;
  padding: 25px 0px;
}

.reasons-div {
  width: 700px;
  display: flex;
  height: 280px;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(77, 67, 67);
  margin: 15px auto;
  border-radius: 10px;
}

.password-div p {
  height: 3em;
  padding: 0px 10px;
  user-select: none;
}

.reason-div {
  width: 30%;
  height: 90%;
  background-color: rgb(36, 33, 33);
  border-radius: 10px;
}

.info-div {
  max-width: 700px;
  width: 80vw;
  display: flex;
  height: 280px;
  justify-content: center;
  align-items: center;
  background-color: rgb(36, 33, 33);
  margin: 15px auto;
  border-radius: 10px;
  border: 1px solid rgb(36, 33, 33);
  flex-direction: column;
}

.info-div p {
  padding: 0px 25px;
}

.info-div-red {
  width: 700px;
  display: flex;
  height: 280px;
  justify-content: center;
  align-items: center;
  background-color: rgb(36, 33, 33);
  margin: 15px auto;
  border-radius: 10px;
  border: 1px solid red;
}

.info-div-orange {
  width: 700px;
  display: flex;
  height: 280px;
  justify-content: center;
  align-items: center;
  background-color: rgb(36, 33, 33);
  margin: 15px auto;
  border-radius: 10px;
  border: 1px solid orange;

}

.info-div-green {
  width: 700px;
  display: flex;
  height: 280px;
  justify-content: center;
  align-items: center;
  background-color: rgb(36, 33, 33);
  margin: 15px auto;
  border-radius: 10px;
  border: 1px solid green;
}

.svg-div {
  height: 75px;
  width: 75px;
  margin: 10px 0px 20px 0px;
  padding: 10px 10px 20px 10px;
}

.svg-div svg {
  fill: white;
  transition: fill 0.5s ease-in-out;
}

.svg-div:hover svg {
  fill: lightgrey; /* Change fill color on hover */
}

.svg-div:active svg {
  fill: red; /* Change fill color when clicked */
  animation: revertColor 0.5s ease forwards; /* Revert fill color animation */
}

@keyframes revertColor {
  0% {
    fill: red;
  }
  100% {
    fill: white;
  }
}

.svg-div:active svg {
  animation: revertColor 0.5s ease forwards;
}



@media only screen and (max-width: 400px) {
  .labels-div {
    align-items: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .label-div {
    width: auto;
  }
  .form-div {
    font-size: 18px;
    width: auto;
  }
  .input-div input {
    width: 100%;
  }
  .password-div h1 {
    font-size: 25px;
  }
}

